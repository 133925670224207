import React from "react"
import MediaQuery from 'react-responsive'
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1.5rem'
  },
  galleryBox: {
    marginBottom: theme.spacing(1),
  },
  image: {
    marginBottom: theme.spacing(1),
  },
  galleryPc: {
    height: '700px',
    padding: theme.spacing(2),
  },
  gallerySp: {
    padding: theme.spacing(2),
  },
}));

const query = graphql`
  query {
    allContentfulGallery(sort: {fields: [createdAt], order: ASC}) {
      edges {
        node {
          caption {
            caption
          }
          image {
            fluid(resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

const Gallery = () => {
  const classes = useStyles();
  const contentful = useStaticQuery(query)

  return <>
    <Typography className={classes.title} variant="h6" gutterBottom>
      ギャラリー
    </Typography>
    <Typography className={classes.galleryBox}>
      <Grid container spacing={3}>
        {contentful.allContentfulGallery.edges.map(({ node }) =>
          <Grid key={node.order} item xs={12} md={6}>
            <MediaQuery minDeviceWidth={1023}> {/* iPad Pro以上 */}
              <Paper variant="outlined" elevation={3} className={classes.galleryPc}>
                <Img alt="" fluid={node.image.fluid} className={classes.image} />
                <Typography >
                  {node.caption.caption}
                </Typography>
              </Paper>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1023}> {/* iPad Pro以下 */}
              <Paper variant="outlined" elevation={3} className={classes.gallerySp}>
                <Img alt="" fluid={node.image.fluid} className={classes.image} />
                <Typography >
                  {node.caption.caption}
                </Typography>
              </Paper>
            </MediaQuery>
          </Grid>
        )}
      </Grid>
    </Typography>
  </>;
}

export default Gallery;